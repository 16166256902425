import { useEffect, useMemo, useState } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import dynamic from 'next/dynamic';
import { PAGE_1_ANIMATIONS } from '@constants/onboarding';
import { isMobile } from '@utils/device/device-detect';
import { useRouter } from 'next/router';
import { useSimplePlayer, useUserBillProfile, useUserInfo } from '@store/state';
import { useOnboardingStore } from '@stores/useOnboardingEventStore';
import { moveMobileUserLogin, moveUserLogin } from '@utils/common/UserApi';
import { movePurchaseProductListOnboarding, openCouponRegisterWithLogin } from '@utils/common/BillApi';
import { MoveToAppHome } from '@components/content/CommonComponent';
import useThrottledFn from '@hooks/useThrottledFn';
import useWindowResize from '@hooks/useWindowResize';
import { css } from '@emotion/react';
import Meta from '@components/meta';
import { pageOrigin, imgOrigin } from '@utils/apiConfig';
import fillDefaultImg from '@utils/onboarding/fillDefaultImg';
import SafeInnerHTML from '@utils/security/SafeInnerHTML';
import Head from 'next/head';
import { SERVICE_INFO } from '@constants/seo';
import {
    StyledWrapper,
    StyledScene0Wrapper,
    StyledScene1Wrapper,
    StyledScene2Wrapper,
    StyledScene3Wrapper,
    StyledScene4Wrapper,
    StyledTitle,
    StyledMainMessage,
    StyledTextWrapper,
    StyledScene0MessageWrapper,
    StyledScene0Message,
    StyledScene0Button,
    StyledVideo4,
    StyledVideo5,
    StyledImage5,
    StyledScene5Wrapper,
    StyledScene6Wrapper,
    StyledNo1Icon,
    StyledPage8Message1,
    StyledPage8Button,
    StyledScene7Wrapper,
    StyledPage7Message,
    StyledFAQ,
    StyledInput,
    StyledLabel,
    StyledFAQContentWrapper,
    StyledFAQContent,
    StyledRegisterCouponWrapper,
    StyledRegisterCoupon,
    StyledQuickVodImg,
    StyledFaqTitle,
} from './styles';
import { useScrollFocus } from './useOnScreen';
import amplitudeEvent from '@tving/utils/src/utils/amplitude/amplitudeEvent';
import useMount from '@tving/utils/src/hooks/common/useMount';
import getBasicEventProperties from '@utils/amplitude/eventProperties/getBasicEventProperties';
import getEventNameByDevice from '@utils/amplitude/eventName/getEventNameByDevice';
import { type OnboardingSectionContent, type OnboardingSectionImage, type OnboardingSectionContents, type OnboardingInfo } from '@typings/onboarding';
import { AspectRatio } from '@tving/ui';
import { openExternalPage } from '@utils/openWindow';

const SimplePlayer = dynamic(
    () => {
        return import('@components/new-player/simplePlayer');
    },
    { ssr: false },
);

const FlowScene = dynamic(
    () => {
        return import('./FlowScene');
    },
    { ssr: false },
);
const ThreeImgGroup = dynamic(
    () => {
        return import('./ThreeImgGroup');
    },
    { ssr: false },
);

const FLOW_SCENE_COUNT = 10;

type OnboardingProps = {
    onboardingInfo?: OnboardingSectionContents[];
    faqList?: any[];
};

const defaultValues: Pick<Required<OnboardingProps>, 'onboardingInfo' | 'faqList'> = {
    onboardingInfo: [],
    faqList: [],
};

const OnBoarding = ({ onboardingInfo = defaultValues.onboardingInfo, faqList = defaultValues.faqList }: OnboardingProps) => {
    const ONBOARDING_INFO = useMemo(() => {
        return onboardingInfo?.reduce<OnboardingInfo>((acc, cur) => {
            return {
                ...acc,
                [cur.section_code]: cur,
            };
        }, {});
    }, [onboardingInfo]);

    const [{ horizontal: pcBannerImgUrl, vertical: moBannerImgUrl }] = ONBOARDING_INFO?.WEB_ONBOARDING_BANNER?.images ?? [{}];
    const { link: bannerLink } = ONBOARDING_INFO?.WEB_ONBOARDING_BANNER ?? '';
    const [{ horizontal, vertical }] = ONBOARDING_INFO?.WEB_ONBOARDING_1?.images ?? [{}];
    const { contents: onboarding3Data } = ONBOARDING_INFO?.WEB_ONBOARDING_3 ?? {};
    const { clips: onboarding4Data } = ONBOARDING_INFO?.WEB_ONBOARDING_4 ?? {};
    const { motion: onboarding5Data } = ONBOARDING_INFO?.WEB_ONBOARDING_5 ?? {};
    const [{ vertical: quickVodImages }] = ONBOARDING_INFO?.WEB_ONBOARDING_6?.images ?? [{}];
    const onboarding7MotionData = ONBOARDING_INFO?.WEB_ONBOARDING_7?.motion ?? null;
    const onboarding7ImageData = ONBOARDING_INFO?.WEB_ONBOARDING_7?.images?.[0] ?? null;

    let onboarding2Data: OnboardingSectionImage[] | OnboardingSectionContent[] | null = null;
    switch (ONBOARDING_INFO?.WEB_ONBOARDING_2?.section_type) {
        case 'IMG':
            onboarding2Data = ONBOARDING_INFO?.WEB_ONBOARDING_2?.images;
            break;
        case 'CONTENTS':
            onboarding2Data = ONBOARDING_INFO?.WEB_ONBOARDING_2?.contents;
            break;
        default:
            break;
    }

    const funSectionImgList = onboarding2Data || [];
    const flowSceneList = fillDefaultImg(onboarding3Data, FLOW_SCENE_COUNT);
    const [simplePlayer] = useSimplePlayer();

    const [{ isLogin }] = useUserInfo();
    const { push, asPath } = useRouter();
    const { data } = useUserBillProfile();
    const { isNotProd } = data || {};

    const [isMobileDevice, setIsMobileDevice] = useState(false);
    const [funImgList, setFunImgList] = useState<OnboardingSectionImage[] | OnboardingSectionContent[]>([]);
    const { setEventLink } = useOnboardingStore();

    useEffect(() => {
        setIsMobileDevice(isMobile);
    }, []);
    useIsomorphicLayoutEffect(() => {
        setFunImgList(funSectionImgList.sort(() => Math.random() - 0.5) || []);
    }, []);

    useEffect(() => {
        setEventLink(ONBOARDING_INFO?.WEB_ONBOARDING_EVENT?.link || '');
    }, [ONBOARDING_INFO, setEventLink]);

    const tvingStartAction = () => {
        const params = asPath?.split('?')[1] || '';
        const isUtmParams = params.includes('utm_source');

        if (isMobile) {
            amplitudeEvent({
                event: 'click_play_now_mo',
                eventProperties: getBasicEventProperties({ screenName: 'onboarding' }),
            });
        } else {
            amplitudeEvent({
                event: 'click_onboarding_start_pc',
                eventProperties: getBasicEventProperties({ screenName: 'onboarding' }),
            });
        }

        if (isLogin) {
            push('/');
        } else if (isMobileDevice) {
            moveMobileUserLogin();
        } else {
            moveUserLogin(isUtmParams ? `?${params}` : '');
        }
    };

    const scrollFocus1 = useScrollFocus({ isOnlyOnce: true });
    const scrollFocus2 = useScrollFocus({ isOnlyOnce: true });

    // 플레이어 라인..
    const [isPlayerView, setPlayerView] = useState(false);
    useEffect(() => {
        if (!simplePlayer.player) {
            return;
        }
        if (isPlayerView) {
            simplePlayer.player.play();
        } else if (simplePlayer.player?.player) {
            simplePlayer.player.pause();
            simplePlayer.player.currentTime = 0;
        }
    }, [isPlayerView]);
    const scrollFocusLineVideo = useScrollFocus({
        intersectingFn: (isView) => {
            setPlayerView(isView);
        },
    });

    const scrollFocusLine4 = useScrollFocus({
        threshold: 0,
        intersectingFn: (isView, dom) => {
            const videoElement = dom.current?.querySelector<HTMLVideoElement>('video');
            if (!videoElement) {
                return;
            }

            if (isView) {
                videoElement.currentTime = 0;
                videoElement.play();
            } else {
                videoElement.pause();
                videoElement.currentTime = 0;
            }
        },
        isOnlyOnce: true,
    });

    const scrollFocusVideo4 = useScrollFocus({
        isOnlyOnce: true,
        intersectingFn: (isView) => {
            if (isView) {
                document.querySelector('.section-4-video')!.classList.add('show');
            }
        },
    });

    const scrollFocusLine5 = useScrollFocus({
        threshold: 0,
        intersectingFn: (isView, dom) => {
            const videoElement = dom.current?.querySelector<HTMLVideoElement>('video');
            if (!videoElement) {
                return;
            }

            if (isView) {
                videoElement.currentTime = 0;
                videoElement.play();
            } else {
                videoElement.pause();
                videoElement.currentTime = 0;
            }
        },
        isOnlyOnce: true,
    });

    const scrollFocusLine6 = useScrollFocus();

    const checkOnlyOne = ({ target }) => {
        const checkboxes = document.getElementsByName('check');
        checkboxes.forEach((item) => {
            if (item !== target) {
                item.checked = false;
            }
        });
    };

    const [width, setWidth] = useState(0);
    useWindowResize(
        useThrottledFn(() => {
            const height = window.innerHeight * 0.8;
            setWidth((height / 9) * 16);
        }, 300),
    );
    useEffect(() => {
        const height = window.innerHeight * 0.8;
        setWidth((height / 9) * 16);
    }, []);

    const getVideoUrl = (verticalImgUrl, horizontalImgUrl) => {
        if (isMobileDevice) {
            return verticalImgUrl && `${imgOrigin}${verticalImgUrl}`;
        }

        return horizontalImgUrl && `${imgOrigin}${horizontalImgUrl}`;
    };

    useMount(() => {
        amplitudeEvent({
            event: getEventNameByDevice('enter_onboarding'),
            eventProperties: getBasicEventProperties({ screenName: 'onboarding' }),
        });
    });

    const onClickBanner = ({ bannerLink }: { bannerLink: string }) => {
        amplitudeEvent({
            event: `click_line_banner_${isMobile ? 'mo' : 'pc'}`,
            eventProperties: {
                screen_name: 'onboarding',
                click_url: bannerLink,
            },
        });
        openExternalPage({ url: bannerLink });
    };

    return (
        <>
            <Head>
                <script
                    id="app-ld-json"
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify(SERVICE_INFO, null, '\t'),
                    }}
                />
            </Head>
            <Meta
                title="TVING"
                isNoIndex={false}
                description="티빙 오리지널부터 드라마•예능, 영화, 스포츠까지! 무제한으로 스트리밍해 보세요."
                keywords=""
                link={pageOrigin}
                thumbnail="/img/tving_logo_meta_new.png"
                openGraph={{
                    twitter: {
                        card: 'summary_large_image',
                    },
                }}
            />
            <StyledWrapper>
                <StyledScene0Wrapper id="scroll-section-0" horizontal={horizontal} vertical={vertical}>
                    <StyledScene0MessageWrapper className="message-title">
                        {pcBannerImgUrl && moBannerImgUrl && (
                            <picture>
                                <source media="(min-width: 768px)" srcSet={`${imgOrigin}${pcBannerImgUrl}`} />
                                <img
                                    className="cursor-pointer w-[24.4rem] pb-[5rem] md:w-[43.6rem] md:pb-[4.2rem]"
                                    src={`${imgOrigin}${moBannerImgUrl}`}
                                    alt="온보딩 배너 이미지"
                                    onClick={() => bannerLink && onClickBanner({ bannerLink })}
                                />
                            </picture>
                        )}

                        <StyledScene0Message className="message-1" isActive sec={PAGE_1_ANIMATIONS.messageA_sec}>
                            티빙 오리지널부터
                        </StyledScene0Message>
                        <StyledScene0Message className="message-2" isActive sec={PAGE_1_ANIMATIONS.messageB_sec}>
                            드라마•예능, 영화, 스포츠까지!
                        </StyledScene0Message>
                        <StyledScene0Message className="message-3" isActive sec={PAGE_1_ANIMATIONS.messageC_sec}>
                            무제한으로 스트리밍해 보세요.
                        </StyledScene0Message>
                        {!isLogin && (
                            <StyledScene0Button
                                type="button"
                                onClick={tvingStartAction}
                                isActive
                                isTivingLogo={false}
                                sec={PAGE_1_ANIMATIONS.button_sec}
                                data-testid="onboarding-login-button"
                            >
                                티빙 로그인
                            </StyledScene0Button>
                        )}
                        {isLogin && isNotProd && (
                            <StyledScene0Button
                                type="button"
                                onClick={() => movePurchaseProductListOnboarding({ isMobile })}
                                isActive
                                isTivingLogo={false}
                                sec={PAGE_1_ANIMATIONS.button_sec}
                                data-testid="onboarding-subscription-button"
                            >
                                이용권 구독하기
                            </StyledScene0Button>
                        )}
                        {isLogin && !isNotProd && (
                            <StyledScene0Button
                                type="button"
                                onClick={() => MoveToAppHome()}
                                isActive
                                isTivingLogo={false}
                                sec={PAGE_1_ANIMATIONS.button_sec}
                                data-testid="onboarding-mobile-app-button"
                            >
                                모바일 APP으로 보기
                            </StyledScene0Button>
                        )}
                    </StyledScene0MessageWrapper>
                </StyledScene0Wrapper>
                {Array.isArray(funSectionImgList) && funSectionImgList.length ? (
                    <StyledScene1Wrapper id="scroll-section-1" {...scrollFocus1}>
                        <div className="content-wrap">
                            <StyledTextWrapper
                                className="message-title"
                                css={css`
                                    padding-top: 4rem;
                                    z-index: 190;
                                    position: relative;
                                `}
                            >
                                <StyledTitle>티빙 오리지널 시리즈</StyledTitle>
                                <StyledMainMessage isFirstLine>오직 티빙에서만 만날 수 있는</StyledMainMessage>
                                <StyledMainMessage>오리지널 콘텐츠를 감상해 보세요.</StyledMainMessage>
                            </StyledTextWrapper>
                            <ThreeImgGroup imgList={funImgList} isMobile={isMobileDevice} />
                        </div>
                    </StyledScene1Wrapper>
                ) : null}
                {Array.isArray(flowSceneList) && flowSceneList.length ? (
                    <StyledScene2Wrapper id="scroll-section-2" {...scrollFocus2}>
                        <StyledTextWrapper className="message-title">
                            <StyledTitle>요즘 뜨는 모든 콘텐츠</StyledTitle>
                            <StyledMainMessage isFirstLine>tvN·JTBC·Mnet 프로그램, 영화,</StyledMainMessage>
                            <StyledMainMessage>해외 시리즈, 애니메이션, 스포츠</StyledMainMessage>
                        </StyledTextWrapper>
                        <FlowScene flowSceneList={flowSceneList} />
                    </StyledScene2Wrapper>
                ) : null}
                {Array.isArray(onboarding4Data) && onboarding4Data.length ? (
                    <StyledScene3Wrapper id="scroll-section-3" {...scrollFocusLineVideo} width={width}>
                        <SimplePlayer mediaCode={onboarding4Data[0]} mode="page" isMuted />
                    </StyledScene3Wrapper>
                ) : null}
                <StyledScene4Wrapper id="scroll-section-4" {...scrollFocusLine4}>
                    <StyledTextWrapper className="message-title">
                        <StyledTitle>언제 어디서나 본방 사수</StyledTitle>

                        {isMobileDevice ? (
                            <>
                                <StyledMainMessage isFirstLine>본방 시작 5분 만에 Quick VOD로</StyledMainMessage>
                                <StyledMainMessage>빠르게 다시 보고,</StyledMainMessage>
                                <StyledMainMessage>놓친 방송은 타임머신으로 돌려보세요.</StyledMainMessage>
                            </>
                        ) : (
                            <>
                                <StyledMainMessage isFirstLine>본방 시작 5분 만에 Quick VOD로 빠르게 다시 보고,</StyledMainMessage>
                                <StyledMainMessage>놓친 방송은 타임머신으로 돌려보세요.</StyledMainMessage>
                            </>
                        )}
                    </StyledTextWrapper>
                    <StyledVideo4
                        src={getVideoUrl(onboarding5Data?.vertical, onboarding5Data?.horizontal)}
                        type="video/mp4"
                        muted
                        playsInline
                        disableRemotePlayback
                        loop
                        {...scrollFocusVideo4}
                        css={{
                            height: '101vw',
                        }}
                    />
                    <StyledQuickVodImg
                        src={quickVodImages ? imgOrigin + quickVodImages : '/img/quick-vod@2x.png'}
                        className="section-4-video"
                        alt="똑똑하게 보는 재미 소개 영상"
                    />
                </StyledScene4Wrapper>
                <StyledScene5Wrapper id="scroll-section-5" {...scrollFocusLine5}>
                    <StyledTextWrapper className="message-title">
                        <StyledTitle>원하는 기기로 자유롭게 감상</StyledTitle>
                        <StyledMainMessage isFirstLine>스마트폰, 태블릿, PC, TV까지!</StyledMainMessage>
                    </StyledTextWrapper>

                    <AspectRatio
                        ratio={isMobileDevice ? 1.221 : 2.068}
                        className="mt-[3.33rem] mb-[6.75rem] mx-[0.093rem] md:mt-[4.67rem] md:mb-[20.08rem] md:-mx-[2.9rem] lg:mx-[14.5rem]"
                    >
                        {onboarding7MotionData ? (
                            <StyledVideo5
                                src={getVideoUrl(onboarding7MotionData?.vertical, onboarding7MotionData?.horizontal)}
                                type="video/mp4"
                                muted
                                playsInline
                                disableRemotePlayback
                                loop
                            />
                        ) : (
                            <StyledImage5 src={getVideoUrl(onboarding7ImageData?.vertical, onboarding7ImageData?.horizontal)} alt="" />
                        )}
                    </AspectRatio>
                </StyledScene5Wrapper>
                <StyledScene6Wrapper id="scroll-section-6" {...scrollFocusLine6}>
                    <StyledNo1Icon src="/img/tving-new-logo-pc.svg" alt="TVING" />
                    <StyledPage8Message1>지금 시작해보세요</StyledPage8Message1>
                    {!isLogin && (
                        <StyledPage8Button type="button" isTivingLogo={false} onClick={tvingStartAction}>
                            티빙 로그인
                        </StyledPage8Button>
                    )}
                    {isLogin && isNotProd && (
                        <StyledPage8Button type="button" isTivingLogo={false} onClick={() => movePurchaseProductListOnboarding({ isMobile })}>
                            이용권 구독하기
                        </StyledPage8Button>
                    )}
                    {isLogin && !isNotProd && (
                        <StyledPage8Button type="button" isTivingLogo={false} onClick={() => MoveToAppHome()}>
                            모바일 APP으로 보기
                        </StyledPage8Button>
                    )}
                </StyledScene6Wrapper>
                {Array.isArray(faqList) && faqList.length ? (
                    <StyledScene7Wrapper id="scroll-section-6">
                        <StyledPage7Message>자주 찾는 질문</StyledPage7Message>
                        <div>
                            {faqList.map((faq) => {
                                return (
                                    faq.useYn === 'Y' && (
                                        <StyledFAQ key={`${faq.title}-${faq.faqSeq}`}>
                                            <StyledInput type="checkbox" id={`faq-${faq.faqSeq}`} name="check" onClick={checkOnlyOne} />
                                            <StyledLabel htmlFor={`faq-${faq.faqSeq}`}>
                                                <StyledFaqTitle>{faq.title}</StyledFaqTitle>
                                            </StyledLabel>
                                            <StyledFAQContentWrapper>
                                                <StyledFAQContent>
                                                    <SafeInnerHTML html={faq.content} />
                                                </StyledFAQContent>
                                            </StyledFAQContentWrapper>
                                        </StyledFAQ>
                                    )
                                );
                            })}
                        </div>
                        <StyledRegisterCouponWrapper>
                            <StyledRegisterCoupon
                                type="button"
                                onClick={() => {
                                    openCouponRegisterWithLogin(isLogin, isMobile);
                                }}
                            >
                                쿠폰 등록하기
                            </StyledRegisterCoupon>
                        </StyledRegisterCouponWrapper>
                    </StyledScene7Wrapper>
                ) : null}
            </StyledWrapper>
        </>
    );
};

export default OnBoarding;
