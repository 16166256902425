import React, { useEffect, useState, useCallback, useRef } from 'react';
import { isMobile } from '@utils/device/device-detect';

type UseScrollFocusParams = {
    isFixed?: boolean;
    duration?: number;
    delay?: number;
    threshold?: number;
    setSlideIdx?: React.Dispatch<React.SetStateAction<number>>;
    intersectingFn?: (isIntersecting: boolean, dom: React.RefObject<HTMLElement>) => void;
    isOnlyOnce?: boolean;
};

export const useScrollFocus = ({
    isFixed = false,
    duration = 1,
    delay = 0,
    threshold = 0.2,
    setSlideIdx,
    intersectingFn = () => {},
    isOnlyOnce = false,
}: UseScrollFocusParams = {}) => {
    const dom = useRef<HTMLElement>(null);

    const handleScroll: IntersectionObserverCallback = useCallback(
        ([entry]) => {
            const { current } = dom;

            if (entry.isIntersecting) {
                current?.classList.add('first', 'in');
                intersectingFn(true, dom);
            } else {
                if (!isOnlyOnce) {
                    current?.classList.remove('in');
                }
                intersectingFn(false, dom);
            }
        },
        [delay, duration],
    );

    useEffect(() => {
        let observer;
        const { current } = dom;
        if (current) {
            observer = new IntersectionObserver(handleScroll, { threshold });
            observer.observe(current);
        }

        return () => observer && observer.disconnect();
    }, [handleScroll]);

    // 스크롤 체크
    useEffect(() => {
        const { current } = dom;
        if (!current || !isFixed) {
            return undefined;
        }

        const headerHeight = document.querySelector('header')?.getBoundingClientRect().height || 0;

        let isScheduleAnimationFrame = false;

        const updateScroll = () => {
            const prevEl = current.previousSibling as HTMLElement;
            const posY = (prevEl?.offsetTop || 0) + prevEl.getBoundingClientRect().height - headerHeight - window.scrollY;

            (current.childNodes[0] as HTMLElement).style.transform = `translateY(0)`;

            if (posY < 0) {
                if (setSlideIdx) {
                    if (isMobile) {
                        setSlideIdx(Math.min(3, Math.ceil((posY / current.offsetHeight) * 6) * -1));
                    } else {
                        setSlideIdx(Math.min(3, Math.ceil((posY / current.offsetHeight) * 6) * -1));
                    }
                }
                const targetNum = (posY + current.offsetHeight) / window.innerHeight;
                if (targetNum < 1) {
                    current.style.opacity = `${Math.min(1, Math.max(0, targetNum * targetNum * targetNum))}`;
                    (current.childNodes[0] as HTMLElement).style.transform = `translateY(-${(1 - targetNum) * 100}px)`;
                }
            } else {
                if (setSlideIdx) {
                    setSlideIdx(0);
                }
                current.style.opacity = `1`;
            }

            isScheduleAnimationFrame = false;
        };

        const scrollList = () => {
            // Prevent multiple rAF callbacks.
            if (isScheduleAnimationFrame) {
                return;
            }
            isScheduleAnimationFrame = true;
            requestAnimationFrame(updateScroll);
        };

        window.addEventListener('scroll', scrollList);
        return () => {
            window.removeEventListener('scroll', scrollList);
        };
    }, [dom, isFixed]);

    return {
        ref: dom,
    };
};

export default function useOnScreen(ref: React.RefObject<HTMLElement>) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), {
            root: null,
            rootMargin: '10px',
            threshold: 1.0,
        });
        observer.observe(ref.current);
        // Remove the observer as soon as the component is unmounted
        return () => {
            observer.disconnect();
        };
    }, [ref]);

    return isIntersecting;
}
