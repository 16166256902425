import Onboarding from '@components/onboarding/OnBoarding';
import { osCode, pocCode, screenCode } from '@constants/onboarding';
import ApiSend from '@utils/common/ApiSend';
import * as Sentry from '@sentry/nextjs';
import AppGuidanceModal from '@components/modal/appGuidanceModal';

const OnBoardingPage = ({ onboardingInfo, faqList }) => {
    return (
        <>
            <Onboarding onboardingInfo={onboardingInfo} faqList={faqList} />
            <AppGuidanceModal />
        </>
    );
};

export const getServerSideProps = async (context) => {
    const { _tving_token = '', authToken = '' } = context.req?.cookies || {};
    try {
        if (_tving_token && _tving_token !== '' && authToken === '') {
            Sentry.configureScope((scope) => {
                scope.setExtra('tvingToken', _tving_token || '');
                scope.setExtra('authToken', authToken || '');
            });
            Sentry.captureMessage('[Custom Error] authToken does not exist - SSR', { level: 'log' });
        }
        // eslint-disable-next-line no-empty
    } catch (e) {}

    const getOnBoardingData = async () => {
        const { data } = await ApiSend({
            url: '/v2/onboard',
            params: {
                screenCode,
                osCode,
                apiKey: '1e7952d0917d6aab1f0293a063697610',
            },
            headers: {
                'Auth-Token': authToken,
            },
        });
        return data?.header?.status === 200 ? data.body : [];
    };

    const getFaqList = async ({ pageNo = 1, pageSize = 10 }) => {
        const { data } = await ApiSend({
            url: `/v2/operator/faqs?pageNo=${pageNo}`,
            params: {
                pocCode,
                pageSize,
                apiKey: '1e7952d0917d6aab1f0293a063697610',
            },
            headers: {
                'Auth-Token': authToken,
            },
        });
        const lists = data?.body?.result?.map(({ faq_seq, title, content, use_yn }) => {
            return {
                faqSeq: faq_seq,
                title,
                content,
                useYn: use_yn,
            };
        });
        return lists;
    };

    let faqList = null;
    let onboardingInfo = null;

    try {
        onboardingInfo = await getOnBoardingData();
        faqList = await getFaqList({ pageNo: 1, pageSize: 10 });
    } catch (e) {
        console.error(e);
    }

    return {
        props: {
            onboardingInfo,
            faqList,
        },
    };
};

export default OnBoardingPage;
