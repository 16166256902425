import DOMPurify from 'isomorphic-dompurify';

/**
 * DOMPurify 기본 설정에서는 target="_blank" 속성을 허용하지 않습니다.
 * 이를 안전하게 사용하기 위해 'rel' 속성을 추가하도록 합니다.
 *
 * @see {@link https://github.com/cure53/DOMPurify/issues/317#issuecomment-698800327 DOMPurify Issue #317}
 */
DOMPurify.addHook('afterSanitizeAttributes', node => {
    if (node.tagName === 'A' && 'target' in node) {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener noreferrer');
    }
});

/**
 * xss 공격을 피하기 위해 html 내 위험요소를 사전에 미리 제거된 상태로 랜더링되도록 합니다.
 * @param {Object} props
 * @param {string} props.html
 * @return {JSX.Element}
 *
 * @example
 * return <SafeInnerHTML html='<p>abc<iframe//src=jAva&Tab;script:alert(3)>def</p>'/> // <p>abc</p>
 */

const SafeInnerHTML = ({ html }) => {
    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }} />;
};

export default SafeInnerHTML;
