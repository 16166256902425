import { TVING_DEFAULT_IMG, TVING_DEFAULT_IMG_PC_URL, TVING_DEFAULT_IMG_MOBILE_URL } from '@constants/onboarding';

/**
 * 배열의 길이가 param으로 받는 count 보다 작으면, count 수 만큼 배열에 기본 이미지를 채워주는 함수
 * @param {Array} 이미지가 담긴 배열, 채워야하는 숫자 ex)['img', 'img', 'img'], 4
 * @return {Array} 이미지가 담긴 배열 ex)['img', 'img', 'img', 'default img']
 */

const fillDefaultImg = (array, count) => {
    if (Array.isArray(array) && array.length > 0 && array.length < count) {
        for (let i = array.length; i < count; i += 1) {
            array.push({
                name: TVING_DEFAULT_IMG,
                horizontal: TVING_DEFAULT_IMG_PC_URL,
                vertical: TVING_DEFAULT_IMG_MOBILE_URL,
            });
        }
    }

    return array;
};

export default fillDefaultImg;
